import axios from 'axios';
import {GET_URL} from '../config/variables';

export default class Road {
    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.lat = props.lat;
        this.long = props.long;
        this.type = props.type;
        this.images = props.images || [];
        this.description = props.description;
    }

    static all() {
        return new Promise(((resolve, reject) => {
            axios.get(GET_URL).then((response) => {
                resolve(response.data.map(item => new Road(item)));
            }, reject);
        }));
    }

    static find(id) {
        return new Promise((resolve) => {
            axios.get(GET_URL+`${id}`).then((response) => {
                resolve(new Road(response.data));
            });
        });
    }

}
